/***********************************************/
// Capa de Servicios para el modulo de Hospitales.

import axios from "axios";
import store from "@/store/store.js";

export default new (class {
  #baseUrl = null;
  errormsg = "";
  message = null;
  header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    };

  constructor() {
    // Inicializar campos y propiedades.
    this.#baseUrl = store.getters.getBaseURL;
  }

  async reload() {
    this.header = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    };
  }

  async getAllRecords() {
    try {
      let response = await axios.get(this.#baseUrl + "/hospitals");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getViewList(active=false) {
    try {
      let response = await axios.get(`${this.#baseUrl}/view-list/hospitals?active=${active}`);
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getRecord(id) {
    try {
      let response = await axios.get(`${this.#baseUrl}/hospitals/${id}`);
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async addRecord(formData) {
    await this.reload();
    try {
      let recordData = new FormData();
      recordData.append("id", formData.id);
      recordData.append("name", formData.name);
      recordData.append("description", formData.description);
      recordData.append("description_english", formData.description_english);
      recordData.append("email", formData.email);
      recordData.append("address", formData.address);
      recordData.append("phone1", formData.phone1);
      recordData.append("phone2", formData.phone2);
      recordData.append("web_page", formData.web_page);
      if (formData.sanitary_license) {
        recordData.append("sanitary_license", formData.sanitary_license);
      } else {
        recordData.append("sanitary_license", ".");
      }
      recordData.append("map_lat", formData.map_lat);
      recordData.append("map_lng", formData.map_lng);
      recordData.append("facebook_profile", formData.facebook_profile);
      recordData.append("instagram_profile", formData.instagram_profile);
      recordData.append("youtube_video", formData.youtube_video);
      recordData.append("membership", formData.membership);
      recordData.append("servicehours", formData.servicehours);
      recordData.append("basic", formData.basic?1:0);
      recordData.append("member", formData.member?1:0);
      recordData.append("premium", formData.premium?1:0);
      let response = await axios.post(this.#baseUrl + "/hospitals", recordData, {
          headers: this.header
        });
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  // Pushes posts to the server when called.
  async updateRecord(formData) {
    try {
      await this.reload();
      let response = await axios.put(
        `${this.#baseUrl}/hospitals/${formData.id}`,
        {
          name: formData.name,
          sanitary_license: formData.sanitary_license
            ? formData.sanitary_license
            : "",
          vencimiento_fecha: formData.vencimiento_fecha,
          coepris_qr_link: formData.coepris_qr_link,
          id: formData.id,
          description: formData.description,
          description_english: formData.description_english,
          email: formData.email,
          address: formData.address,
          phone1: formData.phone1,
          phone2: formData.phone2,
          web_page: formData.web_page,
          map_lat: formData.map_lat,
          map_lng: formData.map_lng,
          facebook_profile: formData.facebook_profile,
          instagram_profile: formData.instagram_profile,
          youtube_video: formData.youtube_video,
          membership: formData.membership,
          servicehours: formData.servicehours,
          active: formData.active,
          basic: formData.basic,
          member: formData.member,
          premium: formData.premium,
        },
        {
          headers: this.header
        }
      );
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async deleteRecord(id) {
    try {
      await this.reload();
      let response = await axios.delete(`${this.#baseUrl}/hospitals/${id}`, {
          headers: this.header
        });
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async registerSpecialties(hospital_id, specialtiesIds) {
    try {
      await this.reload();
      let formData = new FormData();
      formData.append("hospital_id", hospital_id);
      formData.append("specialties_ids", specialtiesIds);
      let response = await axios.post(
        this.#baseUrl + "/register-specialties/hospitals",
        formData,
        {
          headers: this.header
        }
      );
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async deleteSpecialties(hospital_id, specialtiesIds) {
    try {
      await this.reload();
      let formData = new FormData();
      formData.append("hospital_id", hospital_id);
      formData.append("specialties_ids", specialtiesIds);
      let response = await axios.post(
        this.#baseUrl + "/delete-specialties/hospitals",
        formData,
        {
          headers: this.header
        }
      );
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async uploadImage(hospital_id, picture_id, imageFile) {
    if (imageFile) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("hospital_id", hospital_id);
        formData.append("picture_id", picture_id);
        formData.append("file", imageFile);
        let response = await axios.post(
          this.#baseUrl + "/upload-image/hospitals",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  async deleteImage(hospital_id, picture_id) {
    if (picture_id) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("hospital_id", hospital_id);
        formData.append("picture_id", picture_id);
        let response = await axios.post(
          this.#baseUrl + "/delete-image/hospitals",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  //  qr

  async deleteQr(hospital_id, coepris_qr) {
    if (coepris_qr) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("hospital_id", hospital_id);
        formData.append("coepris_qr", coepris_qr);
        let response = await axios.post(
          this.#baseUrl + "/delete-qr/hospitals",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  async uploadQr(hospital_id, qr_code_id, imageFile, isQr = true) {
    if (imageFile) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("hospital_id", hospital_id);
        formData.append("qr_code_id", qr_code_id);
        formData.append("file", imageFile);
        formData.append("isQr", isQr);
        let response = await axios.post(
          this.#baseUrl + "/upload-image/hospitals",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  ////////////////////////////////////////////////

  // vencimiento qr

  async updateVencimientoFecha(hospital) {
    try {
      await this.reload();
      let response = await axios.put(
        `${this.#baseUrl}/hospitals/${hospital.id}`,
        {
          name: hospital.name,
          sanitary_license: hospital.sanitary_license
            ? hospital.sanitary_license
            : ".",
          vencimiento_fecha: hospital.vencimiento_fecha,
          coepris_qr_link: hospital.coepris_qr_link,

          id: hospital.id,
          description: hospital.description,
          description_english: hospital.description_english,
          email: hospital.email,
          address: hospital.address,
          phone1: hospital.phone1,
          phone2: hospital.phone2,
          web_page: hospital.web_page,
          map_lat: hospital.map_lat,
          map_lng: hospital.map_lng,
          facebook_profile: hospital.facebook_profile,
          instagram_profile: hospital.instagram_profile,
          youtube_video: hospital.youtube_video,
          membership: hospital.membership,
          servicehours: hospital.servicehours,
        },
        {
          headers: this.header
        }
      );

      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      // Handle errors here
      return this.handleError(error);
    }
  }

  ///////////////////////////////////////////////////////

  async sendEmail(profile_id, email) {
    try {
      let response = await axios.get(
        `${this.#baseUrl}/send-email/hospitals?id=${profile_id}&email=${email}`
      );
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      let result;
      result = this.handleError(error);
      return result;
    }
  }

  handleError(error) {
    let valueError;
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      valueError = error.response.data.messaje;
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      valueError = error.request.data;
    } else {
      // Something happened in setting up the request and triggered an Error
      valueError = error.message;
    }
    return valueError;
  }
})();

/*console.log('Array de imagenes: ', formData.pictures);

let objeto1 = { id: 1, name: 'Ramon', post: 'La Habana'};
let objeto2 = { id: 2, name: 'Ernesto', post: 'Santiago'};
let objeto3 = { id: 3, name: 'Rene', post: 'Guantanamo'};
let array_objetos = [objeto1, objeto2, objeto3];
let data = {
    id: formData.id,
    name: formData.name,
    pictures: array_objetos
};*/
// axios.post(this.#baseUrl + '/update-record/hospitals', data)
